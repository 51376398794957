// SCSS (& Sass) Variables
// https://quasar.dev/style/sass-scss-variables

// Themes
$gm-primary: (
  50: #fafafa,
  100: #f5f5f5,
  200: #e5e5e5,
  300: #d6d6d6,
  400: #a3a3a3,
  500: #737373,
  600: #525252,
  700: #424242,
  800: #202020,
  900: #121212,
);
$gm-secondary: (
  50: #f5f8ff,
  100: #eaf0ff,
  200: #aac2ff,
  300: #80a4ff,
  400: #5585ff,
  500: #2b67ff,
  600: #2253cc,
  700: #1a3e9a,
  800: #112a67,
  900: #091535,
);

$gm-warning: (
  50: #fffaeb,
  100: #fef0c7,
  200: #fedf89,
  300: #fec84b,
  400: #fdb022,
  500: #f79009,
  600: #dc6803,
  700: #b54708,
  800: #93370d,
  900: #7a2e0e,
);
$gm-negative: (
  50: #fef3f2,
  100: #fee4e2,
  200: #fecdca,
  300: #fda29b,
  400: #ff7d74,
  500: #f04438,
  600: #d92d20,
  700: #b42318,
  800: #912018,
  900: #7a271a,
);
$gm-positive: (
  50: #ecfdf3,
  100: #d1fadf,
  200: #a6f4c5,
  300: #6ce9a6,
  400: #32d583,
  500: #12b76a,
  600: #039855,
  700: #027a48,
  800: #05603a,
  900: #054f31,
);

$gm-logo: (
  color-1: #3867f6,
  color-2: #101010,
  color-3: #d2fd51,
);

$primary: map-get($gm-primary, 900);
$primary-1: #fcfcfd;
$primary-50: map-get($gm-primary, 50);
$primary-100: map-get($gm-primary, 100);
$primary-200: map-get($gm-primary, 200);
$primary-300: map-get($gm-primary, 300);
$primary-400: map-get($gm-primary, 400);
$primary-500: map-get($gm-primary, 500);
$primary-600: map-get($gm-primary, 600);
$primary-700: map-get($gm-primary, 700);
$primary-800: map-get($gm-primary, 800);
$primary-900: map-get($gm-primary, 900);

$secondary: map-get($gm-secondary, 500);
$secondary-50: map-get($gm-secondary, 50);
$secondary-100: map-get($gm-secondary, 100);
$secondary-200: map-get($gm-secondary, 200);
$secondary-300: map-get($gm-secondary, 300);
$secondary-400: map-get($gm-secondary, 400);
$secondary-500: map-get($gm-secondary, 500);
$secondary-600: map-get($gm-secondary, 600);
$secondary-700: map-get($gm-secondary, 700);
$secondary-800: map-get($gm-secondary, 800);
$secondary-900: map-get($gm-secondary, 900);

$accent: map-get($gm-secondary, 50);
$accent2: map-get($gm-primary, 50);

$dark: #18181b;
$dark-page: #18181b;
$dark-secondary: #2a2a2d;

$positive: map-get($gm-positive, 500);
$positive-50: map-get($gm-positive, 50);
$positive-100: map-get($gm-positive, 100);
$positive-200: map-get($gm-positive, 200);
$positive-300: map-get($gm-positive, 300);
$positive-400: map-get($gm-positive, 400);
$positive-500: map-get($gm-positive, 500);
$positive-600: map-get($gm-positive, 600);
$positive-700: map-get($gm-positive, 700);
$positive-800: map-get($gm-positive, 800);
$positive-900: map-get($gm-positive, 900);

$negative: map-get($gm-negative, 500);
$negative-50: map-get($gm-negative, 50);
$negative-100: map-get($gm-negative, 100);
$negative-200: map-get($gm-negative, 200);
$negative-300: map-get($gm-negative, 300);
$negative-400: map-get($gm-negative, 400);
$negative-500: map-get($gm-negative, 500);
$negative-600: map-get($gm-negative, 600);
$negative-700: map-get($gm-negative, 700);
$negative-800: map-get($gm-negative, 800);
$negative-900: map-get($gm-negative, 900);

$warning: map-get($gm-warning, 500);
$warning-50: map-get($gm-warning, 50);
$warning-100: map-get($gm-warning, 100);
$warning-200: map-get($gm-warning, 200);
$warning-300: map-get($gm-warning, 300);
$warning-400: map-get($gm-warning, 400);
$warning-500: map-get($gm-warning, 500);
$warning-600: map-get($gm-warning, 600);
$warning-700: map-get($gm-warning, 700);
$warning-800: map-get($gm-warning, 800);
$warning-900: map-get($gm-warning, 900);

$info: map-get($gm-positive, 500);

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}

// Custom Variables
$placeholder-color: map-get($gm-primary, 400);
$border-color: map-get($gm-primary, 200);

$field-small: (
  height: 44px,
);
$field-normal: (
  height: 48px,
);
$field-large: (
  height: 56px,
);

$translucent: 0.3;
$transition-durations: (
  normal: 0.3s,
  md: 0.5s,
  lg: 0.7s,
  xl: 1s,
);

$generic-hover-transition: map-get($transition-durations, 'normal')
  cubic-bezier(0.25, 0.8, 0.5, 1);

$letter-spacing: (
  negative: -0.5px,
  standard: -0.3px,
  normal: 0px,
  sm: 0.1rem,
  md: 0.2rem,
  lg: 0.3rem,
);

$dimmed-background: rgba(0, 0, 0, 0.4);
$dimmed-background-dark: rgba(0, 0, 0, 0.8);
$light-dimmed-background: rgba(255, 255, 255, 0.08);
$light-dimmed-background2: rgba(255, 255, 255, 0.04);

// Growmodo Extra Colors
$gmc-green: #bcf200;

$border: 8px;
$borders: (
  normal: 1px,
  md: 1.5px,
  lg: 2px,
  xl: 4px,
);

$disable-bg: map-get($gm-primary, 50);
$disable-color: map-get($gm-primary, 700);
$blue-bg: map-get($gm-secondary, 50);
$blue-color: map-get($gm-secondary, 500);
$active-bg: map-get($gm-positive, 50);
$active-color: map-get($gm-positive, 700);
$negative-bg: map-get($gm-negative, 50);
$negative-color: map-get($gm-negative, 700);
$warning-bg: map-get($gm-warning, 50);
$warning-color: map-get($gm-warning, 700);

$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-normal: 500;
$font-medium: 500;
$font-demi-bold: 600;
$font-black: 900;
$font-extra-black: 900;

$shadow-state-size: 4px;

$gm-shadows: (
  sm: (
    0px 1px 2px rgba(115, 115, 115, 0.06),
    0px 1px 3px rgba(115, 115, 115, 0.1),
  ),
  md: (
    0px 2px 4px -2px rgba(115, 115, 115, 0.06),
    0px 4px 8px -2px rgba(115, 115, 115, 0.1),
  ),
  lg: (
    0px 12px 16px -4px rgba(115, 115, 115, 0.08),
    0px 6px 4px -2px rgba(115, 115, 115, 0.03),
  ),
  hover: unset,
  active: (
    0px 0px 0px $shadow-state-size #d5e1ff,
  ),
  error: (
    0px 0px 0px $shadow-state-size #ffd5d5,
  ),
);

$shadow-field: map-get($gm-shadows, 'sm');
$shadow-color: $primary;

$shadow-gm: 4px 4px 0 0 #2b67ff;

// Quasar Defaults Overwrite
$typography-font-family: 'TTNormsPro-Normal', 'Roboto', '-apple-system',
  'Helvetica Neue', Helvetica, Arial, sans-serif;

$button-shadow: $shadow-field;
$button-shadow-active: map-get($gm-shadows, 'active');
$table-box-shadow: $shadow-field;
$layout-shadow: $shadow-field;
$menu-box-shadow: map-get($gm-shadows, 'lg');
$rating-shadow: $shadow-field;

// Borders Radius
$generic-border-radius: $border;
$button-border-radius: $border;
$button-push-border-radius: $border;
$button-group-min-height: 44px;

$toolbar-title-letter-spacing: map-get($letter-spacing, 'normal');

$h1: (
  size: 3rem,
  line-height: 3.75rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 300,
);
$h2: (
  size: 2.25rem,
  line-height: 2.75rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 300,
);
$h3: (
  size: 1.875rem,
  line-height: 2.25rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 400,
);
$h4: (
  size: 1.5rem,
  line-height: 2rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 400,
);
$h5: (
  size: 1.25rem,
  line-height: 1.75rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 400,
);
$h6: (
  size: 1.125rem,
  line-height: 1.5rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 500,
);
$subtitle1: (
  size: 1rem,
  line-height: 1.5rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 400,
);
$subtitle2: (
  size: 0.875rem,
  line-height: 1.25rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 500,
);
$body1: (
  size: 1rem,
  line-height: 1.5rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 400,
);
$body2: (
  size: 0.875rem,
  line-height: 1.25rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 400,
);
$overline: (
  size: 0.75rem,
  line-height: 1.125rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 500,
);
$caption: (
  size: 0.625rem,
  line-height: 1rem,
  letter-spacing: map-get($letter-spacing, 'standard'),
  weight: 400,
);

$body-font-size: 16px;

$input-font-size: map-get($body2, 'size');
$input-label-color: $placeholder-color;
$input-text-color: $primary;

$tooltip-background: $primary;
$tooltip-fontsize: 0.9rem;

$item-base-color: $accent;

$img-content-background: $dimmed-background-dark;

// Cards
$card-height: 170px;
$card-height-mini: calc(#{$card-height} / 2 - 8px);

$card-base-padding: 32px;
$card-base-padding-xs: 16px;
$card-base-padding-sm: 24px;
$card-base-padding-md: 38px;
$card-base-padding-lg: 40px;
$card-base-padding-xl: 48px;

// Major Sizes
$gm-size: (
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px,
  6: 24px,
  7: 32px,
  8: 40px,
  9: 48px,
  10: 64px,
  11: 80px,
  12: 96px,
  13: 128px,
  14: 160px,
  15: 192px,
  16: 224px,
  17: 256px,
);

// Button Sizes
$gm-buttons-xs: (
  height: 32px,
  size: map-get($body1, 'size'),
);
$gm-buttons-sm: (
  height: 36px,
  size: map-get($body2, 'size'),
);
$gm-buttons-md: (
  height: 40px,
  size: map-get($body1, 'size'),
);
$gm-buttons-lg: (
  height: 48px,
  size: map-get($body1, 'size'),
);
$gm-buttons-xl: (
  height: 56px,
  size: map-get($h6, 'size'),
);

$z-fab: 990;
$z-side: 1000;
$z-marginals: 2000;
$z-fixed-drawer: 3000;
$z-fullscreen: 6000;
$z-menu: 6000;
$z-top: 7000;
$z-tooltip: 9000;
$z-notify: 9500;
$z-max: 9998;
